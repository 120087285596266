/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

// Import the SCSS file
import './src/scss/global.scss';
import '@fortawesome/fontawesome-svg-core/styles.css';

export const onClientEntry = () => {
    const pathname = window.location.pathname
    const selectedLanguage = pathname.startsWith('/ar') || pathname === '/ar' ? 'ar' : 'en';
    if (typeof window !== `undefined` && !window.localStorage.getItem('gatsby-i18next-language') && !window.localStorage.getItem('preferedLanguage') ){
        window.localStorage.setItem('gatsby-i18next-language',selectedLanguage);
        console.log("slected language was updated based on the pathname whihc is " + selectedLanguage);
        document.documentElement.lang = selectedLanguage;
    }
}